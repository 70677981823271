<!-- 授课老师编辑 -->
<template>
    <div class="EditTeacher">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">授课老师编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="培训课类别" prop="category" >
                <el-input v-model="form.category" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="授课老师" prop="teacher" >
                <el-select v-model="form.teacher" filterable placeholder="请输入并选择授课老师" :clearable="true">
                    <el-option v-for="item in form.teacherOption" :key="item.id" :label="item.fullname"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="协助比例" prop="assist">
                <el-input v-model="form.assist" placeholder="请输入协助比例"></el-input>
            </el-form-item>
            <el-form-item label="设置日期" prop="setDate">
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="form.setDate"
                    type="date"
                    placeholder="请选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="负责状态">
                <el-select v-model="form.condition" placeholder="请选择负责状态" :clearable="true">
                    <el-option
                      v-for="item in form.conditionChose"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="优先级">
                <el-select v-model="form.first" placeholder="请选择优先级" :clearable="true">
                    <el-option
                      v-for="item in form.firstChose"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="说明">
                <el-input type="textarea" class="textArea-width" v-model="form.explain" 
                placeholder="" rows="4"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" 
              @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form:{
                  category:"",//培训课类别
                  teacher:"",//授课老师
                  teacherOption:[],//授课老师选项
                  assist:"",//协助比例
                  setDate:"",//设置日期
                  condition:1,//负责状态状态
                  conditionChose:[
                      {value: 0,label: '不负责'},
                      {value: 1,label: '在负责'},
                      
                  ],//状态选项
                  first:"1",//优先级
                  firstChose:[
                      {value: '1',label: '1'},
                      {value: '2',label: '2'},
                      {value: '3',label: '3'},
                      {value: '4',label: '4'},
                  ],//状态选项
                  explain:""//说明
               },
               rules:{
                   teacher:[
                       { required: true, message: '授课老师不能为空', trigger: 'blur' },
                   ],
                   setDate:[
                       { required: true, message: '设置日期不能为空', trigger: 'change' },
                   ],
               },
               bool:false
            }
        },
        created() {
            // 授课老师数据
            this.$request({
                url: '/api/humanresources/list',
                method: "POST",
                data: {
                    page: 1,
                    limit: 100
                }
            }).then(res => {
                // console.log(res, "授课老师数据")
                this.form.teacherOption = res.data.list
            })
            
             // 初次渲染
             this.$request({
                 url:'/api/teacher/detail',
                 method:"POST",
                 data:{
                    id:this.$route.query.id
                 }
             }).then(res=>{
                 if(res.code==1){
                     console.log(res,'初次渲染')
                     let {
                         class_category,
                         resources_id,
                         ratio,
                         set_time,
                         status,
                         priority,
                         remark,
                     } = res.data
                     Object.assign( this.form,{
                         category:class_category,//培训课类别
                         teacher:resources_id,//授课老师
                         assist:ratio,//协助比例
                         setDate:set_time,//设置日期
                         condition:status,//负责状态
                         first:priority,//优先级
                         explain:remark,//说明
                     })
                     
             
                 }
             })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.form)
                        if(this.bool){
                            return
                        }
                        this.bool=true
                        let form = this.form
                        this.$request({
                            url:'/api/teacher/edit',
                            method:'POST',
                            data:{
                               id:this.$route.query.id,
                               resources_id:form.teacher, //授课老师
                               status:form.condition,//负责状态
                               ratio:form.assist,//协作比例
                               priority:form.first,//优先级
                               set_time:form.setDate,//设置日期
                               remark:form.explain//说明
                            }
                        }).then(res=>{
                            if(res.code==1){
                                this.$message({
                                  message: '编辑成功',
                                  type: 'success'
                                })
                                setTimeout(() => {
                                	this.goBack()	
                                }, 1500)
                            }else{
                                this.$message({
                                  message: res.msg,
                                  type: 'error'
                                })
                                setTimeout(() => {
                                	this.goBack()	
                                }, 1500)
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) {//取消
                this.goBack()
                this.$refs[form].resetFields();
            },
        }
    }
</script>

<style scoped="scoped">
    .EditTeacher{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #FFFFFF;
        color: #606266;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
